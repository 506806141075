$error: #dc3545;
$success: #28a745;
$primary: #51cbce;
$warning: #fbc658;

html {
  overflow-y: scroll !important; }

.main-panel {
  // background-color: #fff

  > .content {
 }    //margin-top: 0px

  @media(min-width: 992px) {
    // TODO comment lines below when show sidebar and navbar - uncomment when hide
    // width: 100vw !important
 } }    // padding-left: 20px

.sidebar {
  // TODO comment line below to show sidebar
 }  // display: none !important

.navbar {
  // TODO comment line below to show navbar
 }  // display: none !important

.footer {
  // TODO comment line below to show footer
 }  // display: none !important

.header-card {
  padding: 15px 15px 15px !important; }

.group-name {
  font-weight: 400;
  font-size: 16px; }

.observations {
  color: lighten(#1B1E20, 15); }

.max-size-alert {
  color: lighten(#1B1E20, 15);
  width: 50%;

  @media(max-width: 768px) {
    width: 100%; } }

.checkbox {
  margin: 3px 0 0 0 !important; }

.checkbox-label {
  font-size: 14px !important;
  margin-left: 18px;
  color: #66615b !important; }

.required-text {
  color: $error !important; }

.warning-text {
  color: $warning !important; }

.required-input {
  border: 1px solid $error !important; }

.valid-input {
  color: $success !important; }

.clickable {
  cursor: pointer;
  pointer-events: auto; }

.hidden {
  display: none !important; }

.selected {
  color: darken($primary, 15); }

.table-filters {
  cursor: pointer;
 }  // display: none

.show-button {
  color: $primary;
  font-size: 20px; }

.attach-modal {
  //& .modal-dialog-centered
 }  //// justify-content: center

.modal-header {
  border-bottom: 0 !important; }

.modal-body {
  > .attach-img {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 768px) {
      max-height: 70vh; } } }

.modal-footer {
  border-top: 0 !important; }

.btn-transparent {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important; } }

.delete-request {
  display: inline;
  padding: 5px; }

.plus-button {
  padding: 1px 5px; }

.minus-button {
  background-color: $error !important;
  padding: 2px 7px; }

.page-item.active .page-link {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important; }

.pagination {

  &--link {
    color: $primary !important; } }

.fullscreen-loading {
  width: 100vw;
  height: 100vh; }

.back-button-container {
  @media(min-width: 768px) {
    text-align: right !important; } }

.btn-validate {
  @media(max-width: 767px) {
    display: none; }

  &--button {
    margin: 0; }

  &--mobile {
    @media(max-width: 768px) {
      text-align: center !important;
      margin-top: -14px;
      margin-bottom: 5px; }
    @media(min-width: 768px) {
      display: none; } } }

.react-datepicker-wrapper {
  width: 100%; }

.attach-link {
  text-decoration: none !important;
  color: #ffffff;

  &:hover {
    color: #ffffff; } }

.form-group-dates {
  box-shadow: 0 0 0 0 !important;

  & .react-datepicker-wrapper {
    width: 100% !important; } }

.no-auth {
  font-size: 18px;

  h4 {
    margin-top: 10px; } }


.custom-file-label::after {
  content: "Examinar" !important; }
