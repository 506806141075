$primary: #51cbce;

.table-loader {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    margin: 6px;
    border: 6px solid $primary;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s; }

    &:nth-child(2) {
      animation-delay: -0.3s; }

    &:nth-child(3) {
      animation-delay: -0.15s; } } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }
