$primary: #51cbce;

.table {
  &--desktop {
    @media(max-width: 575px) {
      display: none !important; } }

  &--head {
    > tr > th {
      @media(max-width: 767px) {
        font-size: 13px !important; } } }

  &--body {
    font-size: 14px;

    &__actions {
      font-size: 16px; } } }

.table__mobile {
  &--head {
    > tr > th {
      @media(max-width: 767px) {
        font-size: 13px !important; } } }

  &--body {
    font-size: 14px;

    &__actions {
      font-size: 18px; } }

  &--toggler {
    font-size: 16px;
    color: $primary; } }

.no-borders {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none; }
